import Lang from 'ln/lang/Lang';
import register from 'ln/setup/register';
import {renderer} from 'ln/view/Renderer';
import {scan} from 'ln/view/View';
import {setup, SetupConfig} from 'ln/setup/Setup';

import Repeat from 'ln/components/Repeat';
import {ContentPage, ContentPageView} from 'components/content-page/ContentPage';
import {Download, DownloadView} from 'components/download/Download';
import {Heading, HeadingView} from 'components/heading/Heading';
import {RawHtml, RawHtmlView} from 'components/raw-html/RawHtml';
import {StartPage, StartPageView} from 'components/start-page/StartPage';
import {StartPageContentLayerItem, StartPageContentLayerItemView} from 'components/start-page/StartPageContentLayerItem';
import {StartPageTitleLayerItem, StartPageTitleLayerItemView} from 'components/start-page/StartPageTitleLayerItem';
import {StartPageLayer, StartPageLayerView} from 'components/start-page/StartPageLayer';
import {StartPageVariant, StartPageVariantView} from 'components/start-page/StartPageVariant';
import {Image, ImageView} from 'components/image/Image';
import {Site, SiteView} from 'components/site/Site';
import {TargetAudienceSwitch, TargetAudienceSwitchView} from 'components/target-audience-switch/TargetAudienceSwitch';
import {Text, TextView} from 'components/text/Text';
import {Tip, TipView} from 'components/tip/Tip';
import {TopicOverview, TopicOverviewView} from 'components/topic-overview/TopicOverview';
import {TopicPage, TopicPageView} from 'components/topic-page/TopicPage';
import {TwoColumnLayout, TwoColumnLayoutView} from 'components/two-column-layout/TwoColumnLayout';

import {Asset} from 'services/Asset';
import {GlobalsStore, DefaultGlobalsStore} from 'services/GlobalsStore';


export function init(data: SetupConfig) {

    const getSite = () => setup.data('site') as Site;
    const globalsStore = new DefaultGlobalsStore();

    renderer.context.globals = globalsStore;

    const {viewRegistrations} = register(({ view, model }) => {
        view('repeat', Repeat);
        view('Site', () => new SiteView(getSite()));
        model('App\\ContentPage', ContentPage).view(ContentPageView);
        model('App\\Download', dto => new Download(dto, globalsStore));
        view(Download, DownloadView);
        model('App\\Heading', Heading).view(HeadingView);
        model('App\\RawHtml', RawHtml).view(RawHtmlView);
        model('App\\StartPage', StartPage).view(StartPageView);
        model('App\\StartPageContentLayerItem', StartPageContentLayerItem).view(StartPageContentLayerItemView);
        model('App\\StartPageTitleLayerItem', StartPageTitleLayerItem).view(StartPageTitleLayerItemView);
        model('App\\StartPageLayer', StartPageLayer).view(StartPageLayerView);
        model('App\\StartPageVariant', StartPageVariant).view(StartPageVariantView);
        model('App\\Image', Image).view(ImageView);
        model('App\\Site', dto => new Site(dto, globalsStore));
        model('App\\TargetAudienceSwitch', () => new TargetAudienceSwitch(globalsStore));
        view(TargetAudienceSwitch, TargetAudienceSwitchView);
        model('App\\TopicOverview', () => new TopicOverview(globalsStore));
        view(TopicOverview, TopicOverviewView);
        model('App\\Text', Text).view(TextView);
        model('App\\Tip', Tip).view(TipView);
        model('App\\TopicPage', TopicPage).view(TopicPageView);
        model('App\\TwoColumnLayout', TwoColumnLayout).view(TwoColumnLayoutView);
    });

    setup.init(data);

    Lang.add(setup.data('lang'));

    renderer.context.assetPath = function(file: Asset, preset: string = 'large', ext: string = 'png') {
        if (file.ext === 'svg') {
            return decodeURIComponent(setup.route('assetDownload', { file_name: file.file_name }).url());
        }
        else {
            return decodeURIComponent(setup.route('asset', { file_name: file.file_name, preset: preset, ext: ext }).url());
        }
    };

    renderer.context.assetDownloadPath = function(file: Asset) {
        return decodeURIComponent(setup.route('assetDownload', { file_name: file.file_name }).url());
    };

    scan(document.body, viewRegistrations);
}
