import View from 'ln/view/View';

import {Page, TargetAudience, TargetAudienceSpecific} from 'components/page/Page';

import {topicPageTemplate} from 'templates/topic-page';


export class TopicPage extends Page implements TargetAudienceSpecific {

    public readonly classContentItems: object[];
    public readonly contentItems: object[];
    public contentItemsProperty: 'contentItems' | 'classContentItems';
    public readonly targetAudience: TargetAudience;
    public readonly timeRequired: string;
    public readonly title: string;


    public constructor(dto) {
        super({
            classContentItems: dto.class_content_items || [],
            contentItemsProperty: 'contentItems',
            contentItems: dto.content_items || [],
            targetAudience: 'middle-school',
            timeRequired: '',
            title: '',

            ...dto
        });
    }
}


export class TopicPageView extends View<TopicPage> {

    public constructor() {
        super({
            template: topicPageTemplate,
        });
    }

    init(): void {
        window.scroll(0, 0);
    }
}
