import {renderer} from 'ln/view/Renderer';
import View from 'ln/view/View';

import {Asset, getAssetPresetInfo} from 'services/Asset';

import {imageTemplate} from 'templates/image';


type ImageStyle = '-no-float' | '-float-left' | '-float-right';

type ImageWidth = '-w1-4' | '-w1-3' | '-w1-2' | '-w2-3';


export class Image {

    public readonly file: object;
    public readonly label: string;
    public readonly sourceAttribution: string;
    public readonly style: ImageStyle;
    public readonly variants: ImageVariant[];
    public readonly width: ImageWidth;

    public constructor(dto) {
        this.file = dto.file || null;
        this.label = dto.label || '';
        this.sourceAttribution = dto.sourceAttribution || '';
        this.style = dto.style || '-no-float';
        this.variants = dto.variants || [];
        this.width = dto.width || '-w1-3';
    }
}


export type ImageVariantName = 'mobile' | 'tablet' | 'desktop';


export class ImageVariant {

    public readonly file: Asset;
    public readonly name: ImageVariantName;
    public readonly mediaQuery?: string;

    public constructor(dto) {
        this.file = dto.file || null;
        this.name = dto.name || 'mobile';
        this.mediaQuery = dto.mediaQuery || '';
    }
}


export class ImageView extends View<Image> {

    public constructor() {
        super({ template: imageTemplate })
    }

    init() {
        const imgNode = <HTMLImageElement> this.node.querySelector('[js="image"]');
        imgNode.src = renderer.context.assetPath(this.data.file);
        if (this.data.variants) {

            // first, determine the (approximate) fraction of the viewport's width to be filled:
            let targetWidth = '100%';
            if (this.data.style !== '-no-float') {
                switch (this.data.width) {
                    case '-w1-2':
                        targetWidth = '50%';
                        break;
                    case '-w1-3':
                        targetWidth = '33.33%';
                        break;
                    case '-w1-4':
                        targetWidth = '25%';
                        break;
                    case '-w2-3':
                        targetWidth = '66%';
                        break;
                }
            }

            // next, compile comma-separated lists of available images and application suggestions:
            let srcset: string[] = [];
            let sizes: string[] = [];
            for (const variant of this.data.variants) {
                let presetName: string;
                let mediaQuery: string;
                switch (variant.name) {
                    case 'desktop':
                        presetName = 'xlarge';
                        mediaQuery = '(min-width: 1080px)';
                        break;
                    case 'tablet':
                        presetName = 'large';
                        mediaQuery = '(min-width: 768px)';
                        break;
                    case 'mobile':
                        presetName = 'medium';
                        mediaQuery = '';
                        targetWidth = '100%';
                        break;
                }
                const presetInfo = getAssetPresetInfo(presetName);
                srcset.push(`${renderer.context.assetPath(variant.file, presetName)} ${presetInfo.maxWidth}w`);
                sizes.push(`${mediaQuery} ${targetWidth}`);
            }

            imgNode.srcset = srcset.join(', ');
            imgNode.sizes = sizes.join(', ');
        }

        const onLoad = () => window.requestAnimationFrame(() => {
            imgNode.removeEventListener('load', onLoad);
            this.limitSize();
        });
        imgNode.addEventListener('load', onLoad);

        window.addEventListener('resize', () => window.requestAnimationFrame(this.limitSize.bind(this)));
    }

    private limitSize(): void {
        const imgNode = <HTMLImageElement> this.node.querySelector('[js="image"]');
        const measureNode = this.node.querySelector('[js="measure"]');

        const maxWidth = measureNode.getBoundingClientRect().width;
        const maxHeight = (0.95 * window.innerHeight) - 80;
        //                 ^^^^^^                     ^^^^
        //       leave some room for label       approximate height in pixels of app header

        if (!maxWidth || !maxHeight) {
            setTimeout(this.limitSize.bind(this), 25);
            return;
        }

        const {naturalWidth: width, naturalHeight: height} = imgNode;
        const kw = maxWidth / width;
        const kh = maxHeight / height;
        const k = Math.min(kw, kh);

        const imageNode = <HTMLImageElement> this.node.querySelector('[js="image"]');
        imageNode.style.width = `${k * width}px`;
        imageNode.style.height = `${k * height}px`;
    }
}
