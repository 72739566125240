import {AttributeChangeObservable} from 'ln/model/Model';
import {Subscription} from 'ln/signal/Signal';


export function sync<TTarget>(target: TTarget, targetProperty: keyof TTarget): SyncTarget {
    return {
        from<TSource extends AttributeChangeObservable>(source: TSource, sourceProperty: keyof TSource, now?: boolean): Subscription {
            if (now) {
                target[targetProperty] = <any> source[sourceProperty];
            }
            return source.change.filter(property => property.name === sourceProperty)
                                .subscribe(property => target[targetProperty] = property.newValue);
        }
    }
}

interface SyncTarget {
    from<TSource extends AttributeChangeObservable>(source: TSource, sourceProperty: keyof TSource, now?: boolean): Subscription;
}
