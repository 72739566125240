import View from 'ln/view/View';

import {Page} from 'components/page/Page';

import {StartPageLayer} from 'components/start-page/StartPageLayer';
import {StartPageVariant} from 'components/start-page/StartPageVariant';

import {startPageTemplate} from 'templates/start-page';


export class StartPage extends Page {

    public variants: StartPageVariant[];

    public constructor(dto) {
        super({
            variants: [],

            ...dto
        });
    }
}


type Monitor = (this: any, ev: Event) => void;


export class StartPageView extends View<StartPage> {

    private scrollMonitor: Monitor;
    private pageHeightLimiter: Monitor;

    public constructor() {
        super({
            template: startPageTemplate,
        });
    }

    init(): void {
        // Initialize initial scroll position:
        this.translateLayers();

        // The start page has several special needs that we need to disable when another
        // page is active (e.g. scroll monitoring and page height limiting, both because of
        // the parallax effect). So we need to watch the active page.

        this.pageHeightLimiter = () => window.requestAnimationFrame(this.limitPageHeight.bind(this));
        this.scrollMonitor = () => window.requestAnimationFrame(this.translateLayers.bind(this));

        this.enablePageHeightLimiter(this.data.selected);
        this.enableScrollMonitor(this.data.selected);

        this.data.change.filter(({ name }) => name === 'selected').subscribe(({ newValue }) => {
            this.enablePageHeightLimiter(newValue);
            this.enableScrollMonitor(newValue);
        });
    }

    private enablePageHeightLimiter(enable: boolean): void {
        const layerContainer = <HTMLElement> this.node;
        if (enable) {
            // We need to limit the page height according to the "tallest" parallax layer
            // so we can cut off the additional scrollable space that results from the
            // downward translation of background layers. "Tallness" of layers needs to
            // be recomputed when layer content changes. This is mostly the case when
            // the viewport changes size, or when images get loaded:
            window.addEventListener('resize', this.pageHeightLimiter);
            const imageNodes = layerContainer.querySelectorAll('img');
            for (let i = 0, n = imageNodes.length; i < n; ++i) {
                const imageNode: HTMLImageElement = imageNodes[i];
                imageNode.addEventListener('load', this.pageHeightLimiter);
            }
        }
        else {
            // When not on the start page, we disable the height limit & cut off:
            layerContainer.style.height = '';
            layerContainer.style.overflow = '';
            window.removeEventListener('resize', this.pageHeightLimiter);
        }
    }

    private limitPageHeight(): void {
        if (!this.data.selected) return; // (needed because we don't unsubscribe image `load` events)

        // Workaround to avoid scroll overshoot that works most of the time,
        // but can go wrong in some cases. TODO: Needs improvement!
        const layerContainer = <HTMLElement> this.node;
        const layerNodes = layerContainer.querySelectorAll('[js="layer"]');
        layerContainer.style.height = '';
        layerContainer.style.overflow = '';
        window.requestAnimationFrame(() => {
            let maxHeight = 0;
            for (let i = 0, n = layerNodes.length; i < n; ++i) {
                maxHeight = Math.max(maxHeight, layerNodes[i].scrollHeight);
            }
            layerContainer.style.height = `${maxHeight}px`;
            layerContainer.style.overflow = `hidden`;
        });
    }

    private enableScrollMonitor(enable: boolean): void {
        if (enable) {
            window.addEventListener('scroll', this.scrollMonitor);
        }
        else {
            window.removeEventListener('scroll', this.scrollMonitor);
        }
    }

    private translateLayers(): void {
        const scrollTop = window.scrollY;
        const layerNodes = this.node.querySelectorAll('[js="layer"]');
        for (let i = 0, n = layerNodes.length; i < n; ++i) {
            const layerNode = <HTMLElement> layerNodes[i];
            const layer: StartPageLayer = layerNode['_lnview']['data'];
            const z = layer.z / -2;
            layerNode.style.transform = `translateY(${z * scrollTop}px)`;
        }
    }
}
