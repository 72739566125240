import Model from 'ln/model/Model';
import View from 'ln/view/View';

import {Site} from 'components/site/Site';
import {TargetAudience} from 'components/page/Page';

import {sync} from 'services/sync';
import {GlobalsStore} from 'services/GlobalsStore';

import {topicOverviewTemplate} from 'templates/topic-overview';


export class TopicOverview extends Model {

    public site: Site;
    public targetAudience: TargetAudience;

    public constructor(globalsStore: GlobalsStore) {
        super({
            site: null,
            targetAudience: 'secondary-school-1',
        });

        sync(this, 'site').from(globalsStore, 'site', true);
        sync(this, 'targetAudience').from(globalsStore, 'targetAudience', true);
    }
}


export class TopicOverviewView extends View<TopicOverview> {

    public constructor() {
        super({ template: topicOverviewTemplate });
    }
}
