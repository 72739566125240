import Model from 'ln/model/Model';
import {Signal} from 'ln/signal/Signal';
import {renderer} from 'ln/view/Renderer';
import View from 'ln/view/View';

import {Page, TargetAudience, isTargetAudienceSpecific} from 'components/page/Page';
import {StartPage} from 'components/start-page/StartPage';
import {TopicPage} from 'components/topic-page/TopicPage';

import {GlobalsStore} from 'services/GlobalsStore';
import {sync} from 'services/sync';
import {sync as syncSelection} from 'services/Selectable';

import {siteTemplate} from 'templates/site';


export class Site extends Model {

    public readonly hasStartPage: boolean;
    public readonly pages: Page[];
    public readonly title: string;

    public menuIsOpen: boolean;
    public selectedPage: Page;
    public selectedTargetAudience: TargetAudience;
    public selectPreviousPage: Signal<void>;

    public constructor(dto, private readonly globalsStore: GlobalsStore) {
        super({
            pages: [],
            title: '',

            menuIsOpen: false,
            selectedPage: null,
            selectedTargetAudience: 'secondary-school-1',

            ...dto,
        });

        renderer.context.globals.site = this;

        this.selectPreviousPage = new Signal<void>();

        this.hasStartPage = this.pages.some(p => p instanceof StartPage);

        syncSelection(this, 'selectedPage', this.pages, pages => {
            if (!pages || !pages.length) return null;
            const startPages = pages.filter(this.isStartPage.bind(this));
            return startPages.length ? startPages[0] : pages[0];
        });

        for (const page of this.pages) {
            page.navigateBack.subscribe(() => this.selectPreviousPage.dispatch());
        }

        sync(this, 'selectedTargetAudience').from(globalsStore, 'targetAudience', true);
    }

    public getPagesForMenuItems(targetAudience: TargetAudience): Page[] {
        // The following condition must achieve the following:
        //  1. exclude the start page
        //  2. include all remaining pages that are not target audience-specific
        //  3. include all target audience-specific pages with a matching target audience
        return this.pages.filter(p => !(p instanceof StartPage) && (!isTargetAudienceSpecific(p) || p.targetAudience === targetAudience));
    }

    public getPagesForTopicOverview(targetAudience: TargetAudience): Page[] {
        // The following condition must achieve the following:
        //  1. exclude all pages that aren't topic pages
        //  2. include all topic pages with a matching target audience
        return this.pages.filter(p => p instanceof TopicPage && p.targetAudience === targetAudience);
    }

    public isStartPage(page: Page): boolean {
        return page instanceof StartPage;
    }

    public selectPage(page: Page): void {
        this.selectedPage = page;
        this.menuIsOpen = false;
    }

    public selectStartPage(): void {
        const startPages = this.pages.filter(p => p instanceof StartPage);
        if (startPages && startPages.length) {
            this.selectPage(startPages[0]);
        }
    }
}


export class SiteView extends View<Site> {

    private isPoppingState: boolean;

    public constructor();
    public constructor(data: Site);
    public constructor(data?: Site) {
        super({ data, template: siteTemplate })
    }

    init(): void {
        let nextPageSymbolId = 1;
        const pageSymbolsContainerNode = this.node.querySelector('[js="pageSymbols"]');
        for (const page of this.data.pages.filter(p => !!p.symbol)) {
            const symbolNode = document.createElementNS('http://www.w3.org/2000/svg', 'symbol');
            symbolNode.innerHTML = page.symbol;
            const symbolId = `page-symbol-${nextPageSymbolId++}`;
            symbolNode.firstElementChild.id = symbolId;
            page.symbolHref = '#' + symbolId;
            pageSymbolsContainerNode.appendChild(symbolNode.firstElementChild);
        }
        for (const page of this.data.pages.filter(p => !!p.symbolLarge)) {
            const symbolNode = document.createElementNS('http://www.w3.org/2000/svg', 'symbol');
            symbolNode.innerHTML = page.symbolLarge;
            const symbolId = `page-symbol-${nextPageSymbolId++}`;
            symbolNode.firstElementChild.id = symbolId;
            page.symbolLargeHref = '#' + symbolId;
            pageSymbolsContainerNode.appendChild(symbolNode.firstElementChild);
            console.log('added large icon for', page);
        }

        this.setupNavigationHistory();
    }

    private setupNavigationHistory(): void {
        history.replaceState({ selectedPageId: this.data.selectedPage.id }, '');
        this.data.change.filter(p => p.name === 'selectedPage').subscribe(this.onSelectedPageChanged.bind(this));
        this.data.selectPreviousPage.subscribe(() => {
            history.back();
            this.onPopState(history.state);
        });
        window.addEventListener('popstate', e => this.onPopState(e.state));
    }

    private onSelectedPageChanged(): void {
        if (this.isPoppingState) return;
        history.pushState({ selectedPageId: this.data.selectedPage.id }, '');
    }

    private onPopState(state: any): void {
        this.isPoppingState = true;
        try {
            if (state && 'selectedPageId' in state) {
                const pages = this.data.pages.filter(p => p.id === state.selectedPageId);
                if (pages.length) {
                    this.data.selectPage(pages[0]);
                }
            }
        }
        finally {
            this.isPoppingState = false;
        }
    }
}
