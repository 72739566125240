import Model from 'ln/model/Model';
import View from 'ln/view/View';

import {TargetAudience} from 'components/page/Page';

import {GlobalsStore} from 'services/GlobalsStore';
import {sync} from 'services/sync';

import {targetAudienceSwitchTemplate} from 'templates/target-audience-switch';


export class TargetAudienceSwitch extends Model {

    public targetAudience: TargetAudience;

    public constructor(private readonly globalsStore: GlobalsStore) {
        super({
            targetAudience: globalsStore.targetAudience,
        });

        sync(globalsStore, 'targetAudience').from(this, 'targetAudience');
    }
}


export class TargetAudienceSwitchView extends View<TargetAudienceSwitch> {

    public constructor() {
        super({ template: targetAudienceSwitchTemplate });
    }
}
