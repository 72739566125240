import View from 'ln/view/View';

import {addTextStyles} from 'services/addTextStyles';

import {tipTemplate} from 'templates/tip';


export class Tip {

    public readonly text: string;

    public constructor(dto) {
        this.text = dto.text;
    }
}


export class TipView extends View<Tip> {

    public constructor() {
        super({
            template: tipTemplate,
        });
    }

    init(): void {
        const contentNode = this.node.querySelector('[js="content"]');
        contentNode.innerHTML = this.data.text;
        addTextStyles(<HTMLElement> contentNode);
    }
}
