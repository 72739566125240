import {Signal} from 'ln/signal/Signal';
import View from 'ln/view/View';

import {Page} from 'components/page/Page';

import {contentPageTemplate} from 'templates/content-page';


export class ContentPage extends Page {

    public readonly contentItems: object[];
    public readonly title: string;

    public constructor(dto) {
        super({
            contentItems: dto.content_items || [],
            title: '',

            ...dto
        });
    }
}


export class ContentPageView extends View<ContentPage> {

    public constructor() {
        super({
            template: contentPageTemplate,
        });
    }
}
