import Model from 'ln/model/Model';
import View from 'ln/view/View';

import {TargetAudience} from 'components/page/Page';

import {addTextStyles} from 'services/addTextStyles';
import {Asset} from 'services/Asset';
import {GlobalsStore} from 'services/GlobalsStore';
import {sync} from 'services/sync';

import {downloadTemplate} from 'templates/download';


export class Download extends Model {

    public selectedTargetAudience: TargetAudience;
    public readonly file: Asset;
    public readonly targetAudience: TargetAudience;
    public readonly text: string;

    public constructor(dto, globalsStore: GlobalsStore) {
        super({
            selectedTargetAudience: 'secondary-school-1',
        });

        this.file = dto.file || null;
        this.targetAudience = dto.targetAudience || 'secondary-school-1';
        this.text = dto.text || '';

        sync(this, 'selectedTargetAudience').from(globalsStore, 'targetAudience', true);
    }
}


export class DownloadView extends View<Download> {

    public constructor() {
        super({ template: downloadTemplate });
    }

    init(): void {
        const contentNode = this.node.querySelector('[js="content"]');
        contentNode.innerHTML = this.data.text;
        addTextStyles(<HTMLElement> contentNode);
    }
}
