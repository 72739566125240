import View from 'ln/view/View';

import {StartPageLayerItem} from 'components/start-page/StartPageLayerItem';

import {startPageContentLayerItemTemplate} from 'templates/start-page';


export class StartPageContentLayerItem extends StartPageLayerItem {

    public readonly contentItems: object[];

    public constructor(dto) {
        super(dto);

        this.contentItems = dto.content_items || [];
    }
}


export class StartPageContentLayerItemView extends View<StartPageContentLayerItem> {

    public constructor() {
        super({
            template: startPageContentLayerItemTemplate,
        });
    }

    init(): void {
        const node = <HTMLElement> this.node;
        node.style.bottom = this.data.bottom;
        node.style.height = this.data.height;
        node.style.left = this.data.left;
        node.style.right = this.data.right;
        node.style.top = this.data.top;
        node.style.width = this.data.width;
    }
}
