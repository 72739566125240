export abstract class StartPageLayerItem {

    public readonly bottom: string;
    public readonly height: string;
    public readonly left: string;
    public readonly right: string;
    public readonly top: string;
    public readonly width: string;

    protected constructor(dto) {
        this.bottom = dto.bottom || '';
        this.height = dto.height || '';
        this.left = dto.left || '';
        this.right = dto.right || '';
        this.top = dto.top || '';
        this.width = dto.width || '';
    }
}
