import Model, {AttributeChangeObservable} from 'ln/model/Model';

import {Site} from 'components/site/Site';
import {TargetAudience} from 'components/page/Page';


export interface GlobalsStore extends AttributeChangeObservable {
    site: Site;
    targetAudience: TargetAudience;
}


export class DefaultGlobalsStore extends Model implements GlobalsStore {

    public site: Site;
    public targetAudience: TargetAudience;

    public constructor() {
        super({
            site: null,
            targetAudience: 'secondary-school-1',
        });
    }
}
