import View from 'ln/view/View';

import {StartPageLayer} from 'components/start-page/StartPageLayer';

import {startPageVariantTemplate} from 'templates/start-page';


export class StartPageVariant {

    public readonly imagePreset: string;
    public readonly layers: StartPageLayer[];
    public readonly mediaQuery: string;
    public readonly name: string;

    public stylesheetGenerated: boolean;

    public constructor(dto) {
        this.imagePreset = dto.imagePreset || 'large';
        this.layers = dto.layers || [];
        this.mediaQuery = dto.mediaQuery;
        this.name = dto.name;

        for (const layer of this.layers) {
            if (layer instanceof StartPageLayer) {
                layer.variant = this;
            }
        }
    }
}


export class StartPageVariantView extends View<StartPageVariant> {

    public constructor() {
        super({ template: startPageVariantTemplate });
    }

    init(): void {
        this.generateMediaStylesheet();
    }

    generateMediaStylesheet(): void {
        if (this.data.stylesheetGenerated) return;

        const stylesheetNode = document.createElement('style');
        stylesheetNode.innerText =
            `.start-page-variant.-${this.data.name} { display: none; }` +
            `@media ${this.data.mediaQuery} { .start-page-variant.-${this.data.name} { display: inherit; } }`;
        document.head.appendChild(stylesheetNode);

        this.data.stylesheetGenerated = true;
    }
}
