import View from 'ln/view/View';

import {addTextStyles} from 'services/addTextStyles';

import {rawHtmlTemplate} from 'templates/raw-html';


export class RawHtml {

    public readonly html: string;

    public constructor(dto) {
        this.html = dto.html || '';
    }
}


export class RawHtmlView extends View<RawHtml> {

    public constructor() {
        super({ template: rawHtmlTemplate });
    }

    init() {
        const node = <HTMLElement> this.node;

        node.innerHTML = this.data.html;
        addTextStyles(node);

        if (node.querySelector('table')) {
            node.style.overflowX = 'auto';
        }
    }
}
