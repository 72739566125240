export function addTextStyles(element: HTMLElement) {

    const walker = element.ownerDocument.createTreeWalker(element, NodeFilter.SHOW_ELEMENT);
    while (walker.nextNode()) {
        const element = <Element> walker.currentNode;
        switch (element.localName) {
            case 'a':
                element.classList.add('text-style', '-link');
                break;
            case 'b':
            case 'em':
            case 'th':
                element.classList.add('text-style', '-para', '-em', '-color-primary');
                break;
            case 'h1':
                element.classList.add('text-style', '-h1', 'content-item', '-heading');
                break;
            case 'h2':
                element.classList.add('text-style', '-h2', 'content-item', '-heading', '-color-primary');
                break;
            case 'h3':
                element.classList.add('text-style', '-h3', 'content-item', '-heading', '-color-primary');
                break;
            case 'li':
                element.classList.add('text-style', '-para');
                break;
            case 'ol':
                element.classList.add('list-style', '-ordered');
                break;
            case 'ul':
                element.classList.add('list-style', '-unordered');
                break;
            case 'p':
            case 'td':
                element.classList.add('text-style', '-para', 'content-item', '-para');
                break;
            case 'table':
                element.classList.add('table-style');
                break;
            case 'tr':
                element.classList.add('row');
                break;
        }
    }
}
