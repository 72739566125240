import {AttributeChangeObservable} from 'ln/model/Model';


export interface Selectable {
    selected: boolean;
}


export function sync(master: AttributeChangeObservable, selectedPropertyName: string, selectables: Selectable[], getInitialSelection?: (selectables: Selectable[]) => Selectable) {
    if (selectables.length) {
        master.change.filter(p => p.name === selectedPropertyName).subscribe(p => {
            for (const selectable of selectables) {
                selectable.selected = selectable === p.newValue;
            }
        });
        master[selectedPropertyName] = getInitialSelection ? getInitialSelection(selectables) : selectables[0];
    }
}
