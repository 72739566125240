import View from 'ln/view/View';

import {addTextStyles} from 'services/addTextStyles';

import {headingTemplate} from 'templates/heading';


export class Heading {

    public readonly text: string;
    public readonly level: number;

    public constructor(dto) {
        this.text = dto.text;
        this.level = Number(dto.level || 3);
    }
}


export class HeadingView extends View<Heading> {

    public constructor() {
        super({
            template: headingTemplate,
        });
    }

    init() {
        this.node.innerHTML = `<h${this.data.level}>${this.data.text}</h${this.data.level}>`;
        addTextStyles(<HTMLElement> this.node);
    }
}
