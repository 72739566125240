import View from 'ln/view/View';

import {twoColumnLayoutTemplate} from 'templates/two-column-layout';


export class TwoColumnLayout {

    public readonly leftContentItems: object[];
    public readonly rightContentItems: object[];
    public readonly style: string;

    public constructor(dto) {
        this.leftContentItems = dto.left_content_items || [];
        this.rightContentItems = dto.right_content_items || [];
        this.style = dto.style || '';
    }
}


export class TwoColumnLayoutView extends View<TwoColumnLayout> {

    public constructor() {
        super({ template: twoColumnLayoutTemplate });
    }
}
