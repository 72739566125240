export interface Asset {
    name: string;
    file_name: string;
    mime: string;
    ext: string;
    height: number;
    id: string;
    tags: string[];
    width: number;
}


export interface AssetPresetInfo {
    maxWidth: number;
    maxHeight: number;
}


// TODO: To be kept in sync with `laravel/config/dateneditor/assets.php`!
export function getAssetPresetInfo(name: string) {
    switch (name) {
        case 'medium':
            return { maxWidth: 400, maxHeight: 400 };

        case 'large':
            return { maxWidth: 1000, maxHeight: 1000 };

        case 'xlarge':
            return { maxWidth: 1600, maxHeight: 1600 };
    }
}
