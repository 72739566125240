import View from 'ln/view/View';
import {renderer} from 'ln/view/Renderer';
import debounce from 'ln/util/debounce';

import {StartPageLayerItem} from 'components/start-page/StartPageLayerItem';
import {StartPageVariant} from 'components/start-page/StartPageVariant';

import {Asset} from 'services/Asset';

import {startPageLayerTemplate} from 'templates/start-page';


export class StartPageLayer {

    public readonly backgroundImageFile: Asset;
    public readonly items: StartPageLayerItem[];
    public readonly top: number;
    public readonly z: number;

    public variant: StartPageVariant;  // will be set by the parent (variant)

    public constructor(dto) {
        this.backgroundImageFile = dto.background_image_file || null;
        this.items = dto.items || [];
        this.top = dto.top || 0;
        this.z = Number(dto.z || 0);
    }
}


export class StartPageLayerView extends View<StartPageLayer> {

    private image: HTMLImageElement;
    private items: HTMLElement;

    public constructor() {
        super({
            template: startPageLayerTemplate,
        });
    }

    init(): void {
        // We only want to truly initialize (and by that we mean retrieving images
        // from the network) when the layer is actually visible. For this, we check
        // the value of the `display` property:
        const visibilityMonitor = debounce(() => {
            if (!isVisible(this.node)) return;
            window.removeEventListener('resize', visibilityMonitor);
            this.onFirstTimeVisible();
        }, 50);
        window.addEventListener('resize', visibilityMonitor);
        visibilityMonitor();
    }

    private onFirstTimeVisible(): void {
        this.image = <HTMLImageElement> this.node.querySelector('[js="image"]');
        this.items = <HTMLElement> this.node.querySelector('[js="items"]');
        this.items.classList.remove('_hidden');

        // This is how we load a layer's background image:
        //
        //  1) First we fetch a small, scaled-down (approx. 150px) version of the image
        //     which we then scale up. This should give a blurry preview, but load fast.
        //     Seeing a blurry placeholder is arguably better than seeing nothing and
        //     a messed-up / shifted content.
        //  2) Then we load a much larger, but properly scaled version of the same image.
        //     Once it's here, we exchange the blurry preview.
        //
        // Both of these images are prefetched off-screen so one doesn't witness the
        // loading in progress.
        const previewAssetPath = renderer.context.assetPath(this.data.backgroundImageFile, 'preview');
        const fullAssetPath = renderer.context.assetPath(this.data.backgroundImageFile, this.data.variant.imagePreset);
        let loadCount = 0;
        const preloadImage = document.createElement('img');
        preloadImage.addEventListener('load', () => {
            this.image.src = preloadImage.src;
            if (++loadCount < 2) {
                preloadImage.src = fullAssetPath;
            }
        });
        preloadImage.src = previewAssetPath;

        this.image.addEventListener('load', () => {
            this.resizeBackgroundImage();
        });
        window.addEventListener('resize', () => window.requestAnimationFrame(this.resizeBackgroundImage.bind(this)));
    }

    private resizeBackgroundImage(): void {
        const viewportWidth = document.body.getBoundingClientRect().width;
        const k = viewportWidth / this.image.naturalWidth;
        const width = k * this.image.naturalWidth;
        const height = k * this.image.naturalHeight;
        this.image.style.width = `${width}px`;
        this.image.style.height = `${height}px`;
        this.items.style.minWidth = `${width}px`;
        this.items.style.minHeight = `${height}px`;
    }
}


function isVisible(element: Element): boolean {
    return window.getComputedStyle(element).display !== 'none' &&
           (!element.parentElement || isVisible(element.parentElement));
}
