import View from 'ln/view/View';

import {addTextStyles} from 'services/addTextStyles';

import {textTemplate} from 'templates/text';


export class Text {

    public readonly text: string;

    public constructor(dto) {
        this.text = dto.text;
    }
}


export class TextView extends View<Text> {

    public constructor() {
        super({
            template: textTemplate,
        });
    }

    init(): void {
        this.node.innerHTML = this.data.text;
        addTextStyles(<HTMLElement> this.node);
    }
}
