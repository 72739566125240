import Model from 'ln/model/Model';
import {Signal} from 'ln/signal/Signal';

import {Selectable} from 'services/Selectable';


export abstract class Page extends Model implements Selectable {

    public id: string;
    public selected: boolean;
    public symbol: string;
    public symbolHref: string;
    public symbolLarge: string;
    public symbolLargeHref: string;

    public readonly navigateBack: Signal<void>;

    public constructor(dto) {
        super({
            id: '',
            selected: false,
            symbol: '',
            symbolHref: '',
            symbolLarge: '',
            symbolLargeHref: '',

            ...dto,
        });

        this.navigateBack = new Signal<void>();
    }
}


export type TargetAudience = 'middle-school' | 'secondary-school-1';


export interface TargetAudienceSpecific {
    targetAudience: TargetAudience;
}


export function isTargetAudienceSpecific(page: Page): page is Page & TargetAudienceSpecific {
    return typeof page['targetAudience'] === 'string';
}
