import View from 'ln/view/View';

import {StartPageLayerItem} from 'components/start-page/StartPageLayerItem';

import {startPageTitleLayerItemTemplate} from 'templates/start-page';


export class StartPageTitleLayerItem extends StartPageLayerItem {

    public readonly subtitle: string;
    public readonly title: string;
    public readonly useLightColor: boolean;

    public constructor(dto) {
        super(dto);

        this.useLightColor = true;
        this.subtitle = dto.subtitle || '';
        this.title = dto.title || '';
    }
}


export class StartPageTitleLayerItemView extends View<StartPageTitleLayerItem> {

    public constructor() {
        super({
            template: startPageTitleLayerItemTemplate,
        });
    }

    init(): void {
        const node = <HTMLElement> this.node;
        node.style.bottom = this.data.bottom;
        node.style.height = this.data.height;
        node.style.left = this.data.left;
        node.style.right = this.data.right;
        node.style.top = this.data.top;
        node.style.width = this.data.width;
    }
}
